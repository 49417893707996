import { IonText, useIonViewDidEnter } from "@ionic/react";
import React, { useEffect, useState } from "react"

export type TimerState = {
    seconds : number

}

export default class TimerCountdown extends React.Component<any, any> {
    interval : any;

    constructor(props) {
      super(props);
      this.state = {
        seconds: parseInt(props.startTimeInSeconds, 10) || 0
      };
    }
  
    tick() {
      if(this.state.seconds > 0) {
        this.setState(state => ({
          seconds: state.seconds - 1
        }));
      }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
      if(this.props.startTimeInSeconds != prevProps.startTimeInSeconds) {
        this.setState(state => ({
          seconds: this.props.startTimeInSeconds
        }));
      }
    }
  
    componentDidMount() {
      this.interval = setInterval(() => this.tick(), 1000);
    }
  
    componentWillUnmount() {
      clearInterval(this.interval);
    }
  
    formatTime(secs) {
      if(secs < 0) {
        return secs;
      } 
      const hours   = Math.floor(secs / 3600);
      const minutes = Math.floor(secs / 60) % 60;
      const seconds = secs % 60;
      return [hours, minutes, seconds]
          .map(v => ('' + v).padStart(2, '0'))
          .filter((v,i) => v !== '00' || i > 0)
          .join(':');
    }
  
    render() {
      return (
        <div>
          Timer: {this.formatTime(this.state.seconds)}
        </div>
      );
    }
  }