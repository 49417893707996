import React, { useState } from 'react';
import { AlertInput, IonButton, IonChip, IonCol, IonGrid, IonItem, IonList, IonRow, IonText, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import MQTTService from '../service/MQTTService';
import TimerCountdown from './TimerCountdown';
import './PLCEnsayo.css'

interface PLCEnsayoProps {
    service: MQTTService,
    id: string;
}

interface PLCEnsayo {
    EstadoEnsayo: number,
    TiempoEnsayoSegundos: number,
    ListaEnsayoString: string,
    ListaEnsayos: {Nombre: string, Duracion: number, Resultado: number}[],
    NombreMaquina: string,
}

const PLCEnsayo: React.FC<PLCEnsayoProps> = ({ id, service }) => {

    const [createAlert] = useIonAlert();
    const [data, setData] = useState<PLCEnsayo>();
    const [timerDec, setTimerDec] = useState<number>();
    let timer;

    //   const { name } = useParams<{ name: string; }>();

    const onDataCallback = (data: any) => {


        setData(data.omron.tests);
        console.log("onDataCallback", data);
        
        // const omronData = data.omron.tests;
        // if (timer) {
        //     clearInterval(timer);
        // }
        // timer = setInterval(() => {
        //     if(omronData && omronData.TiempoEnsayoSegundos > 0) {
        //         omronData.TiempoEnsayoSegundos --;
        //         setData(omronData);
        //         // data.
        //     }

        // }, 1000);
    }

    useIonViewDidEnter(() => {

        service.request(id, { ping: 1 }).then((data) => {
            console.log(data);
            setData(data.omron.tests);
            return;
        })
        service.subscribe(id, onDataCallback);
        return () => {
            // Destruct
            // service.unsubscribe(onDataCallback);
            console.log("Destruct")
        }
    })

    const handleAlertRemoveTest = (index: string) => {
        service.request(id, { testRemove: index }).then(res => {
            if(res.response && res.response.result) {
                service.request(id, { ping: 1 });
                // alert("Se ha eliminado la tarea correctamente");
            } else {
                alert("Ha ocurrido un error al eliminar en el PLC")
                
            }
            
            
        })
    }
    const handleAlertAddTest = (ensayo: string, duracion: string) => {
        const i = parseInt(duracion);
        service.request(id, { testOperation: ensayo, testDuration: i }).then(res => {
            if(!res.response.result) {
                alert("Se ha producido un error. No se pueden más de 10 tests.");
                return;
            }
            service.request(id, { ping: 1 });
        })

    }
    const handleAddTest = (ev: any) => {
        createAlert({
            header: 'Crear nuevo ensayo',
            message: 'Añadir ensayo a la lista de ensayos.',
            subHeader: 'No olvide pulsar en Renaudar para comenzar',
            inputs: [
                {
                    type: 'text',
                    label: 'Tipo de ensayo',
                    name: 'ensayo',
                    placeholder: 'Tipo de ensayo',
                },
                {
                    type: 'number',
                    label: 'Duración del ensayo',
                    name: 'duracion',
                    placeholder: 'Duracion (Segundos)',

                }
            ],

            buttons: [
                {
                    text: 'Aceptar',
                    role: 'confirm',
                    handler: (data) => {
                        handleAlertAddTest(data.ensayo, data.duracion);
                    }
                },
                {
                    text: 'Cancelar',
                    role: 'cancel'
                }
            ]
        });
    }

    const handleMarcha = (ev:any) => {
        if(!data.ListaEnsayos) {
            alert("No hay ensayos disponibles");
            return;
        }
        
        service.request(id, { testMarcha: 1 }).then(res => {
            // alert("Se ha dado la marcha correctamente");
            service.request(id, { ping: 1 });
        })
    }

    const handleErrorTest = (ev:any) => {
        if(data.EstadoEnsayo != 1) {
            alert("No hay ningun ensayo en ejecución")
            return;
        }
        service.request(id, { testFallo: 1 }).then(res => {
            
            service.request(id, { ping: 1 });
        })
    }
    const handleRemoveTest = (ev:any) => {
        if(!data.ListaEnsayos) {
            alert("No hay ensayos disponibles");
            return;
        }
        const inputs = data.ListaEnsayos.map((a, index) => {
            return {
                type: 'radio',
                label: a.Nombre,
                name: 'index',
                value: String(index),
            } as AlertInput
        })
        createAlert({
            message: 'Seleccionar tarea a eliminar',
            inputs: inputs,
            buttons: [
                
                {
                    text: 'Aceptar',
                    role: 'confirm',
                    handler: (data) => {
                        handleAlertRemoveTest(data);
                    }
                },
                {
                    text: 'Cancelar',
                    role: 'cancel'
                }
            ]
        })
    }

    const RenderLoading = () => {
        return <IonText>Conectando con {id}...</IonText>
    }
    const RenderError = () => {
        return <IonText>El dispositivo no responde</IonText>
    }

    if (!data) {
        return <RenderLoading />
    }
    const estadoDisponible = data.EstadoEnsayo === 0;
    const estadoMarcha = data.EstadoEnsayo === 1;
    const estadoFallo = data.EstadoEnsayo === 2;
    return (
        <IonGrid>

            <IonRow >
                <IonCol>
                    <h3>{data.NombreMaquina}</h3>

                </IonCol>
            </IonRow>
            <IonRow >

                <IonCol sizeXs='3' style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                    <div>Estado máquina</div>
                    <IonChip disabled={!estadoDisponible} color={estadoDisponible ? 'success' : ''}>DISPONIBLE</IonChip>
                    <IonChip disabled={!estadoMarcha} color={estadoMarcha ? 'primary' : ''}>MARCHA</IonChip>
                    <IonChip disabled={!estadoFallo} color={estadoFallo ? 'danger' : ''}>FALLO</IonChip>
                </IonCol>
                <IonCol sizeXs='6'>
                    <IonText>HORAS DE TEST</IonText>
                    {/* <div>{data.TiempoEnsayoSegundos} segundos</div> */}
                    <div><TimerCountdown startTimeInSeconds={data.TiempoEnsayoSegundos} /> </div>

                    <br />

                    <IonText>Lista de Tareas</IonText>
                    <IonList>
                        
                    {data.ListaEnsayos && data.ListaEnsayos.map((a, k) => {
                        return <IonItem key={k} className={'resultado-' + a.Resultado}>
                            {a.Resultado == 0 && <IonChip color={"warning"}>Preparado</IonChip>}
                            {a.Resultado == 1 && <IonChip color={"primary"}>Ejecucion</IonChip>}
                            {a.Resultado == 2 && <IonChip color={"success"}>OK</IonChip>}
                            {a.Resultado == 3 && <IonChip color={"danger"}>Error</IonChip>}
                             {a.Nombre}. Duracion {a.Duracion} s.</IonItem>
                    })}
                    </IonList>
                    


                </IonCol>
                <IonCol sizeXs='3' style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>

                    <IonButton onClick={handleAddTest}>NUEVA ORDEN DE TEST</IonButton>
                    <IonButton onClick={handleRemoveTest} color={"warning"}>BORRAR TAREA</IonButton>
                    <IonButton onClick={handleErrorTest} color={"danger"}>ERROR TEST</IonButton>
                    <IonButton onClick={handleMarcha} color={"success"}>MARCHA / CONTINUAR</IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default PLCEnsayo;
