import mqtt from 'mqtt'

interface RequestList {
    deviceId: string,
    requestId: string,
    resolve: (data: any) => void;
    reject: (err: any) => void;
}
export default class MQTTService {
    client?: mqtt.MqttClient;
    requests : RequestList[] = [];
    requestId = 1;
    topics : {name: string, callback: any}[] = [];

    start() {
        
        this.client = mqtt.connect("wss://api.cloudrtu.com/mqtt", {
            // (User:='1baa93f2c3cb0d287e3252dab6c6d586', Password:='b8cdbd44b29c3b69a64963acc77d4189')

            username: '1baa93f2c3cb0d287e3252dab6c6d586',
            password: 'b8cdbd44b29c3b69a64963acc77d4189'
        }); // create a client
        this.client.on("connect", () => {
            this.client!.subscribe("omron/publish/#", (err) => {
                console.log(err)
            });
        });
        this.client.on('error', (err) => console.log("ERROR", err))

        this.client.on("message", (topic, message) => {
            // message is Buffer
            console.log(topic, message.toString());
            const json = JSON.parse(message.toString());

            if(json.response && json.response.messageId) {
                const mid = json.response.messageId;
                const deviceId = topic.substring(topic.lastIndexOf("/") + 1);
                const request = this.requests.find(a => a.requestId == mid && a.deviceId == deviceId);
                if(request) {
                    request.resolve(json);
                }
            }
            this.topics.filter(a => a.name === topic).forEach(a => {
                a.callback(json);
            })

            // client.end();
        });
    }


    request(id: string, action: any) : Promise<any> {
        return new Promise((resolve, reject) => {
            if(id === "1" || id === "2") {
                resolve(this.handleStaticData());
                return;
            }
            if(!this.client) {
                reject("Invalid service state");
                return;
            }
            const commandId = String(++this.requestId);
            this.requests.push({deviceId: id, requestId: commandId, resolve, reject});
            let command = "";
            for (const key in action) {
                const element = action[key];
                command += key + "=" + element + ";";
            }
            command += "commandId=" + commandId + ";";
            console.log(command);

            this.client.publish("omron/input/" + id, command);

        })
    }
    private handleStaticData() {
        const json = '{"response":{"result":true,"messageId":0},"omron":{"outputs":{"do_00":false,"do_01":false},"tests":{"EstadoEnsayo":1,"TiempoEnsayoSegundos":3690,"ListaEnsayoString":"1. ENSAYO 1 60s resultado=0","NombreMaquina":"MAQUINA PRUEBAS PRESION","ListaEnsayos":[{"Nombre":"ENSAYO 1","Duracion":3980,"Resultado":0}]}}}';

        return JSON.parse(json);
    }

    subscribe(id: string, callback: any) {
        this.topics.push({
            name: 'omron/publish/' + id,
            callback,
        })
    }
    unsubscribe(callback: any) {
        const i = this.topics.findIndex(a => a.callback === callback);
        this.topics.splice(i, 1);
    }
}