import React from 'react';
import { IonButtons, IonContent, IonHeader, IonItem, IonList, IonMenuButton, IonPage, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './Page.css';
import PLCEnsayo from '../components/PLCEnsayo';
import MQTTService from '../service/MQTTService';

const PageEnsayos: React.FC<{ service: MQTTService }> = ({ service }) => {

    //   const { name } = useParams<{ name: string; }>();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Lista de Ensayos</IonTitle>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar lang='es'></IonSearchbar>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Ensayos 1</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonList>
                    <IonItem>
                        <PLCEnsayo service={service} id='74719949065211468947' />
                    </IonItem>
                    <IonItem>
                        <PLCEnsayo service={service} id='1' />
                    </IonItem>
                    <IonItem>
                        <PLCEnsayo service={service} id='2' />
                    </IonItem>
                </IonList>

            </IonContent>
        </IonPage>
    );
};

export default PageEnsayos;
